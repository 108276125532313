































import { Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class Intro extends Vue {
  @Prop() readonly title: string;
  @Prop() readonly text: string;

  extended = false
  height = ''

  get showText() {
    return this.text && this.text.length
  }

  get innerWidth() {
    return window.innerWidth
  }

  set innerWidth(value) {
    if (value > 1500) {
      this.extended = true
      this.height = `height: ${this.calcHeight()}px`
    } else {
      this.height = ''
      this.extended = false
    }
  }

  calcHeight() {
    const oversize = window.innerWidth - 1500
    return oversize / 10 * 5.4 + 392
  }

  onResize() {
    this.innerWidth = window.innerWidth
  }

  created() {
    window.addEventListener('resize', this.onResize)
    this.extended = window.innerWidth > 1500
    if (this.extended) this.innerWidth = window.innerWidth
  }
  
}
