var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"pt-16 mt-4"},[_c('v-row',_vm._l((_vm.aboutUsColumns),function(col,i){return _c('v-column',{key:("how-it-works-column-" + (i + 1)),staticClass:"pb-16",attrs:{"colWidth":"6","heading":_vm.$vuetify.lang.t(("$vuetify.aboutUs.columns." + col + ".heading")),"headingSize":_vm.$vuetify.breakpoint.xs ? 's' : 'm',"headingColor":"bright","text":_vm.$vuetify.lang.t(("$vuetify.aboutUs.columns." + col + ".text")),"textSize":_vm.$vuetify.breakpoint.xs? 's' : 'm',"slimColumns":_vm.$vuetify.breakpoint.lgAndUp}})}),1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('p',{staticClass:"pt-13 pb-16 mb-0",class:[
            {'font--title-m': _vm.$vuetify.breakpoint.smAndUp},
            {'font--title-s': _vm.$vuetify.breakpoint.xs}
          ]},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.aboutUs.story.heading"))+" ")])]),_vm._l((_vm.keysOfStoryMarks),function(mark,i){return _c('v-column',{key:("story-mark-" + (i + 1)),staticClass:"pb-4",attrs:{"colWidth":"12","heading":_vm.$vuetify.lang.t(("$vuetify.aboutUs.story.storyMarks." + mark + ".date")),"headingSize":_vm.$vuetify.breakpoint.xs ? 's' : 'm',"headingColor":"bright","text":_vm.$vuetify.lang.t(("$vuetify.aboutUs.story.storyMarks." + mark + ".event")),"textSize":_vm.$vuetify.breakpoint.xs? 's' : 'm'}})})],2),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('p',{staticClass:"font--text-l font--bold mb-0 pt-6 pb-6"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.aboutUs.nextSteps.heading").toUpperCase())+" ")])]),_vm._l((_vm.keysOfOurNextSteps),function(mark,i){return _c('v-column',{key:("story-mark-" + (i + 1)),staticClass:"pb-4",attrs:{"colWidth":"12","heading":_vm.$vuetify.lang.t(("$vuetify.aboutUs.nextSteps.steps." + mark + ".date")),"headingSize":_vm.$vuetify.breakpoint.xs ? 's' : 'm',"headingColor":"bright","text":_vm.$vuetify.lang.t(("$vuetify.aboutUs.nextSteps.steps." + mark + ".event")),"textSize":_vm.$vuetify.breakpoint.xs? 's' : 'm'}})})],2)],1),_c('v-container',{staticClass:"pb-16",attrs:{"fluid":""}},[_c('v-row',{staticClass:"pb-12",class:[
        {'pt-16': _vm.$vuetify.breakpoint.mdAndUp},
        {'pt-0': _vm.$vuetify.breakpoint.smAndDown} ],attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('p',{staticClass:"pt-16 pb-7 mb-0",class:[
            {'font--title-m': _vm.$vuetify.breakpoint.smAndUp},
            {'font--title-s': _vm.$vuetify.breakpoint.xs}
          ]},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.aboutUs.meetUs.heading"))+" ")])]),_vm._l((_vm.keysOfConferences),function(card,i){return _c('v-col',{key:("conference-" + (i + 1)),attrs:{"cols":_vm.$vuetify.breakpoint.lgAndUp ? 3 : _vm.$vuetify.breakpoint.mdAndUp ?  4 : _vm.$vuetify.breakpoint.smAndUp ? 6 : 12}},[_c('v-card',{staticClass:"pa-1",attrs:{"color":"primary","href":_vm.$vuetify.lang.t(("$vuetify.aboutUs.meetUs.conferences." + card + ".linkUrl"))}},[_c('v-img',{attrs:{"src":("/assets/conferences/" + (_vm.$vuetify.lang.t(("$vuetify.aboutUs.meetUs.conferences." + card + ".imgUrl")))),"height":"170px","contain":""}})],1),_c('p',{staticClass:"font--text-m text-center"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(("$vuetify.aboutUs.meetUs.conferences." + card + ".subtext")))+" ")])],1)})],2)],1),_c('HowItWorks'),_c('Banner',{attrs:{"type":"getStarted"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }