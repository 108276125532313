



























































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Feautere extends Vue {
  @Prop() readonly iconSrc: string;
  @Prop() readonly heading: string;
  @Prop() readonly text: string;
  @Prop() readonly reverse: boolean;

  // COMPUTED
  get paddings(): string {
    return this.$vuetify.breakpoint.lgAndUp ? 'padding-left: 180px; padding-right: 180px; padding-bottom: 152px' : this.$vuetify.breakpoint.xs ? 'padding-left: 16px; padding-right: 16px; padding-bottom: 88px' : 'padding-left: 120px; padding-right: 120px; padding-bottom: 88px'
  }
}
