
















































































import { IExtendedNavItem } from '@/plugins/types'
import { Vue, Component } from 'vue-property-decorator'
import { VuetifyLocale } from 'vuetify/types/services/lang';

@Component
export default class Footer extends Vue {
  extendedFooterNavigation: Array<IExtendedNavItem> = [
    {
      heading: this.$vuetify.lang.t('$vuetify.footer.support'),
      links: [
        {
          path: '/#how-it-works',
          name: this.$vuetify.lang.t('$vuetify.navigation.howItWorks')
        },
        {
          href: this.documentationLink,
          name: this.$vuetify.lang.t('$vuetify.system.documentation')
        },
        // {
        //   path: '/faq',
        //   name: this.$vuetify.lang.t('$vuetify.navigation.faq')
        // },
        {
          path: '/price-list',
          name: this.$vuetify.lang.t('$vuetify.navigation.priceList')
        },
        {
          path: '/contacts',
          name: this.$vuetify.lang.t('$vuetify.navigation.contacts')
        }
      ]
    },
    {
      heading: this.$vuetify.lang.t('$vuetify.footer.buy'),
      links: Object.keys((this.$vuetify.lang.locales.en.footer as VuetifyLocale & {buySellColumnValues: Record<string, string>}).buySellColumnValues).map(
        (key: string) => {
          return {
            name: `${this.$vuetify.lang.t('$vuetify.navigation.buy')} ${this.$vuetify.lang.t(`$vuetify.footer.buySellColumnValues.${key}`)}`,
            path: `/prices/${key}`
          }
        }
      )
    },
    {
      heading: this.$vuetify.lang.t('$vuetify.footer.sell'),
      links: Object.keys((this.$vuetify.lang.locales.en.footer as VuetifyLocale & {buySellColumnValues: Record<string, string>}).buySellColumnValues).map(
        (key: string) => {
          return {
            name: `${this.$vuetify.lang.t('$vuetify.navigation.sell')} ${this.$vuetify.lang.t(`$vuetify.footer.buySellColumnValues.${key}`)}`,
            path: `/prices/${key}`
          }
        }
      )
    },
    {
      heading: this.$vuetify.lang.t('$vuetify.footer.priceTrends'),
      links: Object.keys((this.$vuetify.lang.locales.en.footer as VuetifyLocale & {priceTrendsColumnsValue: Record<string, string>}).priceTrendsColumnsValue).map(
        (key: string) => {
          return {
            name: `${this.$vuetify.lang.t(`$vuetify.footer.priceTrendsColumnsValue.${key}`)} ${this.$vuetify.lang.t(`$vuetify.footer.price`)}`,
            path: `/prices/${key}`
          }
        }
      )
    },
    {
      heading: this.$vuetify.lang.t('$vuetify.footer.company'),
      links: [
        {
          path: '/about-us',
          name: this.$vuetify.lang.t('$vuetify.navigation.aboutUs')
        },
        // {
        //   path: '/careers',
        //   name: this.$vuetify.lang.t('$vuetify.navigation.career')
        // },
        {
          path: '/terms',
          name: this.$vuetify.lang.t('$vuetify.navigation.terms')
        },
        {
          path: '/privacy',
          name: this.$vuetify.lang.t('$vuetify.navigation.privacy')
        },
        {
          path: '/aml',
          name: this.$vuetify.lang.t('$vuetify.navigation.aml')
        },
      ]
    }
  ]

  // COMPUTED
  get licenseLink(): string {
    return `${window.location.origin}${process.env.VUE_APP_LICENSE_LINK}`
  }

  get documentationLink(): string {
    return process.env.VUE_APP_DOCUMENTATION_LINK;
  }

  // METHODS
  openHref(href: string) {
    window.open(href, '_blank');
  }

}
