























import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ColorsMixins from '@/mixins/ColorsMixins';
import { ICurrencyInfo } from '@/plugins/types';

@Component
export default class Chart extends mixins(ColorsMixins) {
  @Prop() readonly currency: ICurrencyInfo;
  @Prop() readonly chartLoader: boolean;

  @Watch('currency')
  onChangeGraphDate() {
    this.$emit('startUpdateGraph')
    this.onChartReady()
    this.$emit('graphUpdated')
  }

  chartData: Array<Array<string | number | object>> = [];

  // COMPUTED
  get crosshairColor(): string {
    return this.hexToRGB(this.getColorCode('heading'), 0.2)
  }

  get tooltipBGColor(): string {
    return this.getColorCode('bg-dark')
  }

  get vAxisColor(): string {
    return this.hexToRGB(this.getColorCode('primary'), 0.2)
  }

  get gridLinesColor(): string {
    return this.getColorCode('blur')
  }

  get chartOptions() {
    return {
      title: '',
      height: this.$vuetify.breakpoint.xs ? 420 : 520,
      lineWidth: 2,
      chartArea: {
        top: 0,
        left: 0,
        width: '100%',
        height: this.$vuetify.breakpoint.xs ? 370 : 470,
      },
      axisTitlesPosition: 'none',
      curveType: 'function',
      colors: [this.getColorCode('heading')],
      crosshair: {
        color: this.crosshairColor,
        focused: {
          color: this.crosshairColor,
          opacity: 1,
        },
        selected: {
          color: this.crosshairColor,
          opacity: 0.5,
        },
        orientation: 'vertical',
        trigger: 'both',
      },
      explorer: {
        actions:  [],
      },
      pointShape: '',
      hAxis: {
        title: 'date',
        textStyle: {
          color: this.getColorCode('blur'),
          fontSize: 12,
          bold: false,
          italic: false,
        },
        textPosition: 'out',
        slantedText: false,
        maxAlternation: 1,
        maxTextLines: 1,
        viewWindowMode: 'maximized',
        gridlines: {
          color: this.gridLinesColor,
        },
        minorGridlines: {
          color: 'none',
        },
        baselineColor: this.gridLinesColor,
      },
      vAxis: {
        title: 'rate',
        textStyle: {
          color: this.getColorCode('blur'),
          fontSize: 10,
          bold: false,
          italic: false,
        },
        textPosition: 'in',
        gridlines: {
          color: this.gridLinesColor,
        },
        minorGridlines: {
          color: 'none',
        },
        baselineColor: this.gridLinesColor,
      },
      tooltip: {
        isHtml: true,
        trigger: 'both',
      },
      legend: {
        position: 'none',
      },
      backgroundColor: {
        stroke: 'transparent',
        strokeWidth: 0,
        fill: 'transparent'
      },
      animation:{
        startup: true,
        duration: 500,
        easing: 'linear'
      }
    }
  }

  get mainFiatCurrencySymbol(): string {
    return process.env.VUE_APP_MAIN_FIAT_CURRENCY_SYMBOL
  }

  // METHODS
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async onChartReady() {
    this.chartData = []
    const dates: Array<string | Date> | null = this.currency.graphDates
    const rates: Array<number> | null = this.currency.graph

    if (rates && rates.length && dates && dates.length) {
      const data: Array<Array<Record<string, string | object | number>>> = [];
      data.push([{ label: 'date' }, { label: 'rate' }, { role: 'tooltip', type: 'string', p: { html: true, role: 'tooltip' } }])
      for (let i = 0; i < rates.length; i++) {
        const date = new Date(dates[i])
        const dateAtString = `${(date.toLocaleString('default', { month: 'short' })).toUpperCase()} ${date.getDate()}`
        data.push([ { v: dateAtString }, { v: rates[i] }, { p: {}, v: this.generateTooltipMarkup(rates[i], date) } ])
      }
      this.chartData = data
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  generateTooltipMarkup(rate: number, date: any): string {
    return `
      <div class="d-flex align-center justify-center flex-column rounded-lg text-center heading--text" style="border: none; outline: none; width: 140px; height: 60px; background: ${this.tooltipBGColor}">
        <span style="font-size: 10px; width: 100%">${date.toLocaleString('default', { hour12: false, month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</span>
        <span class="font--text-xs">${this.mainFiatCurrencySymbol} ${rate.toFixed(2)}</span>
      </div>
    `
  }
}
