



















































































































































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component'
import ColorsMixins from '@/mixins/ColorsMixins';

@Component
export default class Banner extends mixins(ColorsMixins) {
  /**
   * Possible types:
   * -- getStarted
   * -- download
   * -- subscribe
   * -- vacancy
   * -- currency
   */
  @Prop() readonly type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({default: undefined}) readonly fluidContainer: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({default: false}) readonly dark: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({default: ''}) readonly qrSrc: any;
  @Prop() readonly role: string;
  @Prop() readonly currencyName: string;
  @Prop({default: ''}) readonly identationTop: string;
  @Prop({default: ''}) readonly identationBottom: string;

  inputHeight = ''
  email = ''
  subscribed = false
  requestPending = false

  // COMPUTED
  get heading(): string {
    if (this.type === 'subscribe') {
      return ''
    }
    if (this.type === 'vacancy') {
      return `${this.$vuetify.lang.t(`$vuetify.system.joinUsAs`)} ${this.role}`
    }
    if (this.type === 'currency') {
      return `${this.$vuetify.lang.t(`$vuetify.system.buy`)} ${this.currencyName} ${this.$vuetify.lang.t(`$vuetify.system.now`)}`
    }
    if (this.type === 'download') {
      return this.$vuetify.lang.t(`$vuetify.banner[${this.type}].heading`)
    }
    if (this.type === 'getStarted') {
      return this.$vuetify.lang.t(`$vuetify.banner[${this.type}].heading`)
    }
    return ''
  }

  get text() {
    return this.type !== 'download'
      ? this.type === 'vacancy'
        ? this.$vuetify.lang.t(`$vuetify.banner[${this.type}].text`)
        : ''
      : this.$vuetify.lang.t(`$vuetify.banner[${this.type}].text`)
  }

  get btnValue() {
    if (this.type === 'currency') {
      return `${this.$vuetify.lang.t(`$vuetify.system.buy`)} ${this.currencyName}`
    }
    return this.$vuetify.lang.t(`$vuetify.banner[${this.type}].buttonValue`)
  }

  get bgPrimaryColor(): string {
    return this.getColorCode('bg-dark')
  }

  get borderColor(): string {
    return this.getColorCode('input-border')
  }

  get valid(): boolean {
    return !(/[\W\w\d]+@[\W\w\d]+\.[\W\w\d]+/).test(this.email)
  }

  get isFluidContainer(): boolean {
    return this.fluidContainer  || this.fluidContainer === '' || this.fluidContainer !== undefined && this.fluidContainer.length || false
  }

  get doIdentationTop(): boolean {
    return this.identationTop !== '' || Boolean(this.identationTop.length) || false
  }

  get doIdentationBottom(): boolean {
    return this.identationBottom !== '' || Boolean(this.identationBottom.length) || false
  }

  get isDark(): boolean {
    return this.dark === '' || this.dark
  }

  get showQr(): boolean {
    return this.qrSrc !== ''
  }

  // METHODS
  async subscribeEvent() {
    this.requestPending = true
    try {
      window.grecaptcha.ready(async () => {
        await window.getCapcha();
        const token = window.capchaToken;
        if (token) {
          const body = {
            token,
            mail: this.email,
            name: this.email,
            message: 'Subscribe to news about the FERVIDO VISA card'
          }
          await fetch('/web/messages', {
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          })
          this.requestPending = false
          this.subscribed = true;
          this.email = '';
        }
      })
    } catch (e) {
      this.requestPending = false
    }
  }

  action() {
    switch (this.type) {
      case 'subscribe':
        this.subscribeEvent()
        break;
      case 'download':
        break;
      case 'getStarted':
      default:
        window.location = process.env.VUE_APP_LOGIN;
        break;
    }
  }

  // MOUNTED LIFE CYCLE HOOK
  mounted() {
    if (this.type !== 'vacancy') {
      const btn = (this.$refs.btn as Vue & {height: string})
      this.inputHeight = btn.height
    }
  }
}
