





















































































































































import { Customer, Product, WhyBlockCol } from '@/plugins/types'
import { Component } from 'vue-property-decorator'
import { VuetifyLocale } from 'vuetify/types/services/lang'
import { mixins } from 'vue-class-component'
import ColorsMixins from '@/mixins/ColorsMixins';

@Component
export default class HomePage extends mixins(ColorsMixins) {
  productsHeadingHeight: string | number = 'auto'
  productsTextHeight: string | number = 'auto'
  whyColsHeadingHeight: string | number = 'auto'
  
  // COMPUTED
  get bgPrimaryColor(): string {
    return this.getColorCode('bg-dark')
  }

  get cardBackgroundStyles(): string {
    const cardWidth = this.$vuetify.breakpoint.lgAndUp ? '50%' : '80%' 
    const cardYPos = this.$vuetify.breakpoint.lgAndUp ? '20%' : this.$vuetify.breakpoint.xs ? '19.5%' : '17%'
    const zebraWidth = this.$vuetify.breakpoint.lgAndUp ? '100%' : this.$vuetify.breakpoint.xs ? '200%' : '110%'
    const zebraYPos = this.$vuetify.breakpoint.lgAndUp ? '4%' : this.$vuetify.breakpoint.xs ? '14%' : '10%'
    const zebraXPos = this.$vuetify.breakpoint.lgAndUp ? 'left' : this.$vuetify.breakpoint.xs ? '0%' : 'center'
    const gradient = `linear-gradient(5.17deg, ${this.hexToRGB(this.bgPrimaryColor, 0)} 80%, ${this.bgPrimaryColor} 90%) 0% 0% / 100% no-repeat`
    return `background: url('assets/simplecard.png') 50% ${cardYPos} / ${cardWidth} no-repeat, ${gradient}, url('/assets/zebra.svg') ${zebraXPos} ${zebraYPos} / ${zebraWidth}  no-repeat`
  }

  get handedPhoneBackgroundStyles (): string {
    const zebraWidth = this.$vuetify.breakpoint.mdAndUp ? '100%' : '140%'
    const zebraXPos = this.$vuetify.breakpoint.mdAndUp ? 'left' : 'center'
    const zebraYPos = this.$vuetify.breakpoint.lgAndUp ? '100px' : 'bottom'
    return `position: relative; padding-top: 878px; background: url('/assets/handedPhone.png') center top / auto no-repeat, url('/assets/zebraLight.svg') ${zebraXPos} ${zebraYPos} / ${zebraWidth} no-repeat`
  }

  get productsColumns(): Array<string>  {
    return Object.keys((this.$vuetify.lang.locales.en.home as VuetifyLocale & {products: {columns: Record<string, Product>}}).products.columns)
  }
  
  get customerColumns(): Array<string> {
    return Object.keys((this.$vuetify.lang.locales.en.home as VuetifyLocale & {customers: {columns: Record<string, Customer>}}).customers.columns)
  }

  get whyBlockColumns(): Array<string> {
    return Object.keys((this.$vuetify.lang.locales.en.home as VuetifyLocale & {whyBlock: {columns: Record<string, WhyBlockCol>}}).whyBlock.columns)
  }


  // METHODS
  goTo(path: string) {
    this.$router.push({ path: path });
  }

  // LIFE CYCLE HOOKS
  mounted() {
    const products: Array<Vue> = this.$refs.products as Array<Vue>
    const whyCols: Array<Vue> = this.$refs.whyCols as Array<Vue>
    if (whyCols) {
      this.whyColsHeadingHeight = whyCols.reduce((acc: number, item: Vue) => {
        const height = (item.$refs.heading as HTMLParagraphElement).getBoundingClientRect().height
        if (height > acc) {
          acc = height;
        }
        return acc;
      }, 0)
    }
    if (products) {
      this.productsHeadingHeight = products.reduce((acc: number, item: Vue) => {
        const height = (item.$refs.heading as HTMLParagraphElement).getBoundingClientRect().height
        if (height > acc) {
          acc = height;
        }
        return acc;
      }, 0)
      this.productsTextHeight = products.reduce((acc: number, item: Vue) => {
        const height = (item.$refs.text as HTMLParagraphElement).getBoundingClientRect().height
        if (height > acc) {
          acc = height;
        }
        return acc;
      }, 0)
    }
  }

}
