import { Vue, Component } from 'vue-property-decorator';
import { Theme } from 'vuetify/types/services/theme';

interface ExtendedTheme extends Theme {
  isDark: boolean;
}

@Component
export default class ColorsMixins extends Vue {
  hexToRGB(hex: string, alpha: number | null = null): string {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
  
    if (alpha || (typeof alpha === 'number' && alpha >= 0)) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  }
  
  getColorCode(colorName: string): string {
    return this.$vuetify.theme.themes[ (this.$vuetify.theme as ExtendedTheme).isDark ? 'dark' : 'light'][colorName] as string
  }
}