













































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Column extends Vue {
  @Prop() readonly colWidth: number|string;
  @Prop({default: undefined}) readonly preHeading: string|undefined;
  @Prop({default: 'string'}) readonly preHeadingType: string; // string / number
  @Prop() readonly heading: string;
  @Prop({default: 'l'}) readonly headingSize: string; // l m s
  @Prop({default: undefined}) readonly headingHeight: string;
  @Prop({default: ''}) readonly headingColor: string;
  @Prop() readonly text: string;
  @Prop({default: 'm'}) readonly textSize: string;
  @Prop({default: undefined}) readonly textHeight: string;
  @Prop({default: ''}) readonly textColor: string;
  @Prop({default: undefined}) readonly buttonText: string|undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() readonly slimColumns: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() readonly htmlFormat: any;

  get preHeadingFonts(): string {
    return this.preHeading
      ? this.preHeadingType === 'string'
        ? 'font--text-m'
        : `${this.$vuetify.breakpoint.xs ? 'font--title-m'  : 'font--title-l'} bright--text`
      : ''
  }

  get headingFonts(): string {
    switch (this.headingSize) {
      case 'm': {
        return 'font--heading-xl'
      }
      case 's': {
        return 'font--heading-m'
      }
      case 'l':
      default: {
        return 'font--title-m'
      }
    }
  }

  get colorForHeading(): string {
    return `${this.headingColor}--text`
  }

  get textFonts(): string {
    switch (this.textSize) {
      case 's': {
        return 'font--text-m'
      }
      case 'm':
      default: {
        return 'font--text-l'
      }
    }
  }

  get colorForText(): string {
     return `${this.textColor}--text`
  }

  get slimCol(): boolean {
    return this.slimColumns || this.slimColumns === '' || false
  }

  get useHtmlFormat(): boolean {
    return this.htmlFormat || this.htmlFormat === '' || false
  }

  action() {
    this.$emit('action');
  }
}
