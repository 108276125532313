

















































import { ICurrencyInfo } from '@/plugins/types';
import { Vue, Component } from 'vue-property-decorator';
import { currencies } from '@/store'

@Component
export default class CurrencyList extends Vue {
  searchText = ''
  listLineHeight = 96
  shadowElementsIndexes: Array<number> = []

  // COMPUTED
  get currenciesArray(): Array<ICurrencyInfo> {
    const currenciesArray = Object.keys(currencies.allCurrencies).map((code: string) => currencies.allCurrencies[code])
    return currenciesArray
  }

  get maxDisplayedCurrency() {
    return this.currenciesArray.length < 10 ? this.currenciesArray.length : 10
  }

  get displayedCurrencies(): Array<ICurrencyInfo> {
    return this.searchText.length
      ? this.currenciesArray.filter((c: ICurrencyInfo) => (c.code && c.code.toLowerCase().includes(this.searchText.toLowerCase()) || (c.name && c.name.toLowerCase().includes(this.searchText.toLowerCase()))))
      : this.currenciesArray
  }

  get currencyListStyles(): string {
    return `overflow-y: scroll; height: ${this.listLineHeight * (this.maxDisplayedCurrency) + (this.listLineHeight / 1.5)}px`
  }

  get shadowIndexes(): Array<number> {
    return this.shadowElementsIndexes
  }

  // METHODS
  openCrypto($event: string) {
    this.$router.push({name: 'Currency', params: { code: $event }})
  }

  listScroll() {
    const list: HTMLElement = this.$refs.currenciesList as HTMLElement;
    const shadowElementsCount = 4
    if (this.displayedCurrencies.length > shadowElementsCount) {
      let i = 0;

      const indexes: Array<number> = []

      if (list.scrollTop % 96 !== 0) {
        i += (list.scrollTop - list.scrollTop % this.listLineHeight) / this.listLineHeight
      }
      if (this.maxDisplayedCurrency + i < this.displayedCurrencies.length) {
        for (i; i <= shadowElementsCount; i++) {
          if (
            (this.displayedCurrencies.length - 1 >= this.maxDisplayedCurrency - 1 - shadowElementsCount + i) 
          ) {
            indexes.push(this.displayedCurrencies.length  - shadowElementsCount + i)
          }
        }
      }
      this.shadowElementsIndexes = indexes
    }
  }

  // LIFE CYCLE HOOKS
  async mounted () {
    await currencies.getCurrencies(true)
    this.$emit('listLoaded')
    if (this.currenciesArray.length > 5) {
      // this.shadowElementsIndexes = [(this.maxDisplayedCurrency - 1), (this.maxDisplayedCurrency),(this.maxDisplayedCurrency + 1)]
      this.listScroll()
    }
    await this.currenciesArray.forEach((c: ICurrencyInfo) => {
      if (c.code) {
        currencies.getSingleCurrencyInfo({code: c.code, period: '1M'})
      }
    })
  }

}
