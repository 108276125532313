





























import { Component } from 'vue-property-decorator'
import { currencies, vacancies } from '@/store';
import { mixins } from 'vue-class-component'
import ColorsMixins from '@/mixins/ColorsMixins';
import Translations from '@/mixins/i18n';

@Component
export default class App extends mixins(ColorsMixins, Translations) {
  showMenu = false

  //COMPUTED
  get isDocPage(): boolean {
    const path: string = this.$route.path
    return path === '/aml' || path === '/terms' || path === '/privacy'
  }

  get isVacancyPage(): boolean {
    return !!this.$route.params.role && !!this.$route.params.index
  }

  get isCurrencyPage(): boolean {
    return !!this.$route.params.code
  }

  get currencyName(): string {
    if (this.isCurrencyPage && this.$route.params.code) {
      return currencies.allCurrencies[this.$route.params.code]?.name as string
    }
    return '';
  }

  get unicornBackground(): string {
    const width = this.$vuetify.breakpoint.lgAndUp ? '100%' : '110%'
    const posX = this.$vuetify.breakpoint.lgAndUp ? '100%' : '0%'
    return `background: url('/assets/unicorn.png') ${posX} 1px / ${width} no-repeat`
  }

  get introData(): Record<string, string> {
    if (this.isDocPage) {
      return {
        title: this.$vuetify.lang.t(`$vuetify.navigation.${this.$route.name?.toLowerCase()}`),
        text: ''
      }
    }
    if (this.isVacancyPage) {
      return {
        title: vacancies.allVacancies.length ? vacancies.allVacancies[Number(this.$route.params.index)].role : '',
        text: vacancies.allVacancies.length ? vacancies.allVacancies[Number(this.$route.params.index)].description : ''
      }
    }
    if (this.isCurrencyPage) {
      return {
        title: `${this.$vuetify.lang.t(`$vuetify.system.buy`)} ${this.$route.params.code} ${this.$vuetify.lang.t(`$vuetify.system.now`)}`,
        text: this.i18n('$vuetify.currencyReview.underTitleText', this.currencyName)
      }
    }
    if (!this.$route.name) {
      return {
        title: 'Page not found',
        text: ''
      }
    }
    return {
      title: this.$vuetify.lang.t(`$vuetify.intro.${this.$route.name?.toLowerCase()}.title`),
      text: this.$vuetify.lang.t(`$vuetify.intro.${this.$route.name?.toLowerCase()}.underTitleText`)
    }
  }
}

