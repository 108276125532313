

























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class DgmSwitch extends Vue {
  /* eslint-disable */
  @Prop() readonly value: any;
  /* eslint-enable */

  get variable(): boolean {
    return this.value;
  }

  set variable(value: boolean) {
    this.$emit('input', value);
    this.$emit('click');
  }

  get theme(): string {
    return this.$vuetify.theme.dark ? 'dark' : 'light';
  }
}
