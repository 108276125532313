var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:[
    ( _obj = {}, _obj[_vm.identationTop] = _vm.doIdentationTop, _obj ),
    {'py-16 mt-2 mb-14 ': !_vm.doIdentationBottom || !_vm.doIdentationTop},
    ( _obj$1 = {}, _obj$1[_vm.identationBottom] = _vm.doIdentationBottom, _obj$1 ),
    {'dark--text': !_vm.isDark} ],staticStyle:{"position":"relative"},attrs:{"fluid":_vm.isFluidContainer}},[(!_vm.subscribed && !_vm.requestPending)?_c('v-row',{staticClass:"rounded-xl mx-auto",class:[
      {'bg-light': !_vm.isDark},
      {'bg-dark': _vm.isDark},
      {'py-14': _vm.$vuetify.breakpoint.lgAndUp},
      {'d-block py-10 px-4 text-center': _vm.$vuetify.breakpoint.mdAndDown},
      {'align-center px-10': _vm.isFluidContainer},
      {'px-14': _vm.$vuetify.breakpoint.lgAndUp && !_vm.isFluidContainer}
    ],style:(("width: " + (_vm.$vuetify.breakpoint.xs || _vm.isFluidContainer ? '100%' : '84%') + "; min-height: 182px"))},[_c('v-col',{staticClass:"py-0",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown || _vm.type === 'vacancy' ? 12 : _vm.showQr ? 7 : 8}},[_c('div',{staticClass:"d-flex flex-column height--full",class:[
          {'justify-space-between': _vm.$vuetify.breakpoint.lgAndUp && _vm.text.length},
          {'justify-center': _vm.$vuetify.breakpoint.lgAndUp && !_vm.text.length}
        ]},[(_vm.type === 'subscribe')?[_c('v-text-field',{staticClass:"rounded-lg custom-placeholder",class:[
              {'mb-3': _vm.$vuetify.breakpoint.mdAndDown}
            ],attrs:{"color":_vm.borderColor,"background-color":_vm.hexToRGB(_vm.bgPrimaryColor, 0.1),"rules":[
              function () { return _vm.email.length > 3 ? (/[\W\w\d]+@[\W\w\d]+\.[\W\w\d]+/).test(_vm.email) || _vm.$vuetify.lang.t("$vuetify.banner.subscribe.mailError") : ''; }
            ],"flat":"","full-width":"","hide-details":"","height":_vm.inputHeight,"placeholder":_vm.$vuetify.lang.t("$vuetify.banner.subscribe.placeholder"),"outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]:[(_vm.heading.length)?_c('p',{staticClass:"font--heading-m mb-0",class:[
              {'pb-4': _vm.$vuetify.breakpoint.mdAndDown && _vm.text.length},
              {'pb-6': _vm.$vuetify.breakpoint.mdAndDown && !_vm.text.length}
            ]},[_vm._v(" "+_vm._s(_vm.heading)+" ")]):_vm._e(),(_vm.text.length)?_c('p',{staticClass:"font--text-m mb-0",class:[
              {'pb-5': _vm.$vuetify.breakpoint.mdAndDown}
            ]},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e()]],2)]),(_vm.type !== 'vacancy')?_c('v-col',{staticClass:"py-0",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown ? 12 : 4}},[_c('v-btn',{ref:"btn",staticClass:"rounded-lg",attrs:{"color":_vm.isDark ? 'bright' : 'dark',"block":"","depressed":"","height":"68","disabled":_vm.type === 'subscribe' ? _vm.valid : false},on:{"click":_vm.action}},[_c('span',{staticClass:"font--text-l text--capitaize-first-letter",class:[
              {'bright--text': !_vm.isDark},
              {'dark--text': _vm.isDark}
            ]},[_vm._v(" "+_vm._s(_vm.btnValue)+" ")])])],1):_vm._e(),(_vm.showQr)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"1"}},[_c('v-img',{attrs:{"max-width":"80","max-height":"80","contain":"","src":_vm.qrSrc}})],1):_vm._e()],1):(_vm.requestPending && !_vm.subscribed)?_c('v-row',{staticClass:"bg-light rounded-xl mx-auto px-14 py-16 text-center",staticStyle:{"width":"84%","min-height":"182px"},attrs:{"align":"center"}},[_c('Loader')],1):_c('v-row',{staticClass:"bg-light rounded-xl mx-auto px-14 py-16 text-center",staticStyle:{"width":"84%","min-height":"182px"},attrs:{"align":"center"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"font--text-l mb-0",domProps:{"innerHTML":_vm._s(this.$vuetify.lang.t("$vuetify.banner.subscribe.subscribedMsg"))}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }