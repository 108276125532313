



























































































































































































































import { ICurrencyGlossaryItem, ICurrencyInfo } from '@/plugins/types';
import { currencies } from '@/store';
import { Component, Watch} from 'vue-property-decorator'
import { VuetifyLocale } from 'vuetify/types/services/lang';
import { mixins } from 'vue-class-component';
import Translations from '@/mixins/i18n';

@Component
export default class CurrencyReview extends mixins(Translations) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loader = false
  chartLoader = true
  diaposons: Array<string> = [
    '1D',
    '1W',
    '1M',
    '6M',
    '1Y'
  ]
  selectedDiaposonIndex = 2

  @Watch('currency')
  onCurrencyChange(value, oldValue) {
    if (value && oldValue && value.code !== oldValue.code) {
      this.selectedDiaposonIndex = 2
    }
  }
  
  // COMPUTED
  get currency(): ICurrencyInfo {
    return currencies.allCurrencies[this.$route.params.code]
  }

  get currenciesArray(): Array<ICurrencyInfo> {
    const currenciesArray = Object.keys(currencies.allCurrencies).map((code: string) => currencies.allCurrencies[code])
    return currenciesArray
  }

  get stringDiaposon(): string {
    return this.diaposons[this.selectedDiaposonIndex]
  }

  get changesKeys(): Array<string> {
    if (this.currency) {
      const keys = Object.keys((this.$vuetify.lang.locales.en.system as VuetifyLocale & {diaposons: Record<string, string>}).diaposons)
      return keys.filter((key: string) => key !== 'changed1y' && key !== 'changed6m' && this.currency[key])
    }
    return []
  }

  get statsKeys(): Array<string> {
    if (this.currency) {
      const keys = Object.keys((this.$vuetify.lang.locales.en.system as VuetifyLocale & {currencyStats: Record<string, string>}).currencyStats)
      return keys.filter((key: string) => this.currency[key])
    }
    return []
  }

  get currencyLinks(): ICurrencyGlossaryItem | null {
    const code: string | null = this.$route.params.code || this.currency.code
    if (code) {
      return currencies.glossary[code]
    }
    return null
  }

  // METHODS
  async onChangeDiaposon() {
    this.chartLoader = true;
    await currencies.getSingleCurrencyInfo({code: this.currency.code as string, period: this.stringDiaposon})
  }

  changesValueColor(value: number) {
    const procent = Number((value - 100).toFixed(2))
    if (procent > 0) {
      return 'success'  
    }
    if (procent < 0) {
      return 'danger'
    }
    return 'disabled'
  }

  changeValueToString(value: number) {
    return `${ Number((value - 100 ).toFixed(2)) > 0 ? '+' : Number((value - 100 ).toFixed(2)) < 0 ? '-' : ''}  ${Math.abs(Number((value - 100).toFixed(2)))} %`
  }

  shortValue(val: number): string | null {
    let amount = 0;
    let abr = '';
    const shortTo: number = val.format(2, 3, ' ', '.', false).split(' ').length;
    if (shortTo <= 2) {
      amount = val / 1000
      abr = ' K'
    } else if (shortTo === 3) {
      amount = val / 1000000
      abr = ' M'
    } else if (shortTo >= 4) {
      amount = val / 1000000000
      abr = ' B'
    }

    if (amount !== 0 && abr.length) {
      return amount.format(1, 0, ' ', '.', false) + abr
    } else {
      return null
    }
  }

  // LIFE CYCLE HOOKS
  async mounted() {
    if (!Object.keys(currencies.allCurrencies).length) {
      this.loader = true
      await currencies.getCurrencies()
      this.loader = false
    }
  }
}
