










































































































import {Component, Prop} from 'vue-property-decorator'
import { mixins } from 'vue-class-component';
import ColorsMixins from '@/mixins/ColorsMixins';
import { ICurrencyInfo } from '@/plugins/types';

@Component
export default class CurrenciesListItem extends mixins(ColorsMixins) {
  @Prop() readonly currency: ICurrencyInfo;
  @Prop() readonly colorForHoverEffect: string;
  @Prop() readonly height: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({default: undefined}) readonly shadowed: any;
  @Prop() readonly shadowValue: number;

  loading = true
  mouseOver = false

  //COMPUTED
  get doShadow(): boolean {
    return this.shadowed || this.shadowed === '' || false
  }

  get hoverColor() {
    return this.hexToRGB(this.getColorCode(this.colorForHoverEffect), 0.2)
  }
  
  get borderStyles() {
    const border = `1px solid ${this.hexToRGB(this.getColorCode('primary'), 0.2)}`
    return {
      top: `border-top: ${border}`,
      bottom: `border-bottom: ${border}`
    }
  }

  get formatedPriceChange() {
    return this.currency && this.currency.changed1d
      ? (this.currency.changed1d - 100).toFixed(2)
      : ''
  }

  get currencyCode(): string {
    return this.currency.code || ''
  }

  get mainFiatCurrencySymbol(): string {
    return process.env.VUE_APP_MAIN_FIAT_CURRENCY_SYMBOL
  }

  // METHODS
  action() {
    this.$emit('action', this.currencyCode)
  } 
}
