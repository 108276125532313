import { ICurrencyGlossaryItem } from "./types";

const glossary: Record<string, ICurrencyGlossaryItem> = {
    'BCH': {
        name: 'Bitcoin Cash',
        code: 'BCH',
        website: 'https://bch.info/en/',
        whitepaper: 'https://bch.info/bitcoin.pdf'
      },
      'BNB': {
        name: 'Binance Coin',
        code: 'BNB',
        website: 'https://www.binance.com/en/bnb',
        whitepaper: null
      },
      'DOGE': {
        name: 'Dogecoin',
        code: 'DOGE',
        website: 'https://dogecoin.com/',
        whitepaper: 'https://github.com/dogecoin/dogecoin/blob/master/README.md'
      },
      'DOT': {
        name: 'Polkadot',
        code: 'DOT',
        website: 'https://polkadot.network/',
        whitepaper: 'https://polkadot.network/PolkaDotPaper.pdf'
      },
      'ETH': {
        name: 'Ethereum',
        code: 'ETH',
        website: 'https://ethereum.org/en/',
        whitepaper: 'https://ethereum.org/en/whitepaper/'
      },
      'TRX': {
        name: 'TRON',
        code: 'TRX',
        website: 'https://tron.network/',
        whitepaper: 'https://developers.tron.network/docs'
      },
      'USDT': {
        name: 'Tether',
        code: 'USDT',
        website: 'https://tether.to/faqs/',
        whitepaper: 'https://tether.to/wp-content/uploads/2016/06/TetherWhitePaper.pdf'
      },
      'XBT': {
        name: 'Bitcoin',
        code: 'XBT',
        website: 'https://bitcoin.org/en/',
        whitepaper: 'https://bitcoin.org/bitcoin.pdf'
      },
      'XLM': {
        name: 'Stellar',
        code: 'XLM',
        website: 'https://www.stellar.org/',
        whitepaper: 'https://www.stellar.org/papers/stellar-consensus-protocol'
      },
      'XRP': {
        name: 'Ripple',
        code: 'XRP',
        website: 'https://xrpl.org/',
        whitepaper: 'https://ripple.com/files/ripple_consensus_whitepaper.pdf'
      },
      'ADA': {
        name: 'Cardano',
        code: 'ADA',
        website: 'https://cardano.org/',
        whitepaper: 'https://docs.cardano.org/introduction'
      },
      'LTC': {
        name: 'Litecoin',
        code: 'LTC',
        website: 'https://litecoin.org/',
        whitepaper: 'https://whitepaper.io/document/683/litecoin-whitepaper'
      }
} 

export default glossary;