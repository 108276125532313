



































import { HowItWorkCol } from '@/plugins/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { VuetifyLocale } from 'vuetify/types/services/lang';

@Component
export default class HowItWorks extends Vue {
  @Prop({default: undefined}) readonly headingText: string | undefined;
  headingHeight: string | number = 'auto'

  // COMPUTED
  get howItWorksColumns(): Array<string> {
    return Object.keys((this.$vuetify.lang.locales.en.howItWorks as VuetifyLocale & {columns: Record<string, HowItWorkCol>}).columns)
  }

  mounted() {
    const cols: Array<Vue> = this.$refs.cols as Array<Vue>;
    if (cols) {
      this.headingHeight = cols.reduce((acc: number, item: Vue) => {
        const height = (item.$refs.heading as HTMLParagraphElement).getBoundingClientRect().height
        if (height > acc) {
          acc = height;
        }
        return acc;
      }, 0)
    }
  }

}
