var render = function () {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4, _obj$5;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"text-center",class:[
    {'px-13': _vm.slimCol}
  ],attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown ? '12' : _vm.colWidth}},[(_vm.preHeading)?_c('p',{staticClass:"pb-6 mb-0 text-uppercase",class:_vm.preHeadingFonts,style:((_vm.preHeadingType === 'number' ? 'font-style: italic; font-weight: normal;' : 'opacity: 40%'))},[_vm._v(" "+_vm._s(_vm.preHeading)+" ")]):_vm._e(),(_vm.heading)?_c('p',{ref:"heading",staticClass:"mb-6",class:[
      ( _obj = {}, _obj[_vm.headingFonts] = _vm.headingFonts.length, _obj ),
      ( _obj$1 = {}, _obj$1[_vm.colorForHeading] = _vm.colorForHeading.length > 6, _obj$1 ) ],style:((_vm.headingHeight && _vm.$vuetify.breakpoint.lgAndUp ? (" height: " + _vm.headingHeight + "px;"): ''))},[_vm._v(" "+_vm._s(_vm.heading)+" ")]):_vm._e(),(!_vm.useHtmlFormat)?[(_vm.text)?_c('p',{ref:"text",staticClass:"mb-6",class:[
        ( _obj$2 = {}, _obj$2[_vm.textFonts] = _vm.textFonts.length, _obj$2 ),
        ( _obj$3 = {}, _obj$3[_vm.colorForText] = _vm.colorForText.length > 6, _obj$3 )
      ],style:((_vm.textHeight && _vm.$vuetify.breakpoint.lgAndUp ? (" height: " + _vm.textHeight + "px;"): ''))},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e()]:[(_vm.text)?_c('p',{staticClass:"pb-6 mb-0",class:[
        ( _obj$4 = {}, _obj$4[_vm.textFonts] = _vm.textFonts.length, _obj$4 ),
        ( _obj$5 = {}, _obj$5[_vm.colorForText] = _vm.colorForText.length > 6, _obj$5 )
      ],domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e()],(_vm.buttonText)?_c('v-btn',{attrs:{"max-width":"311","width":"100%","height":"100%","max-height":"68","depressed":"","color":"bright","large":""},on:{"click":_vm.action}},[_c('span',{staticClass:"dark--text font--text-l font--bold",style:(_vm.$vuetify.breakpoint.xs ? 'font-size: 18px !important' : '')},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }