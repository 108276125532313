


















































import { Contact } from '@/plugins/types'
import { Vue, Component } from 'vue-property-decorator'
import { VuetifyLocale } from 'vuetify/types/services/lang'


@Component
export default class ContactsPage extends Vue {
  // COMPUTED
  get clientContacts(): Array<string> {
    return Object.keys((this.$vuetify.lang.locales.en.contacts as VuetifyLocale & {contacts: {client: Record<string, Contact>}}).contacts.client)
  }

  get bussinessContacts(): Array<string> {
    return Object.keys((this.$vuetify.lang.locales.en.contacts as VuetifyLocale & {contacts: {bussiness: Record<string, Contact>}}).contacts.bussiness)
  }

}
