import Vue from 'vue'
import Vuex from 'vuex'
import { getModule } from 'vuex-module-decorators'

import CurrenciesStore from './currencies'
import VacanciesStore from './careers'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    currencies: CurrenciesStore,
    vacancies: VacanciesStore
  }
})
export default store;
export const currencies: CurrenciesStore = getModule(CurrenciesStore, store);
export const vacancies: VacanciesStore = getModule(VacanciesStore, store);
