






























































































































import { Component } from 'vue-property-decorator';
import { vacancies } from '@/store';
import { IVacancy } from '@/plugins/types';
import { mixins } from 'vue-class-component'
import ColorsMixins from '@/mixins/ColorsMixins';

@Component
export default class VacancyReview extends mixins(ColorsMixins) {
  selectedVacancy: IVacancy | null = null
  loader = true
  // COMPUTED
  get index(): number {
    return Number(this.$route.params.index)
  }

  get role(): string {
    return this.$route.params.role
  }

  get vacancy(): IVacancy | null {
    return this.selectedVacancy
  }

  set vacancy(value) {
    this.selectedVacancy = value
  }

  get vacanciesList(): Array<IVacancy> {
    return vacancies.allVacancies
  }

  get borderStyles() {
    const border = `1px solid ${this.hexToRGB(this.getColorCode('primary'), 0.2)}`
    return {
      top: `border-top: ${border}`,
      bottom: `border-bottom: ${border}`
    }
  }

  get advantageSkillExist(): boolean {
    return this.vacancy && this.vacancy.advantageRequirements && Boolean(this.vacancy.advantageRequirements.length) || false
  }

  get mainFiatCurrencySymbol(): string{
    return process.env.VUE_APP_MAIN_FIAT_CURRENCY_SYMBOL
  }

  // METHODS
  openVacancy(role: string, i: number) {
    this.$router.push({name: 'VacancyReview', params: { role, index: i.toString(), }})
    this.selectedVacancy = this.selectedVacancy = vacancies.allVacancies[i]
  }

  // LIFE CYCLE HOOKS
  async mounted () {
    if (!vacancies.allVacancies.length) {
      setTimeout(async () => {
        await vacancies.getVacancies()
        this.selectedVacancy = vacancies.allVacancies[this.index]
        this.loader = false
      }, 3000);
    } else {
      this.selectedVacancy = vacancies.allVacancies[this.index]
      this.loader = false
    }
  }
}
