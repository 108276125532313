






































































































































import { AboutUsColumn, IConference } from '@/plugins/types'
import { Vue, Component } from 'vue-property-decorator'
import { VuetifyLocale } from 'vuetify/types/services/lang'

@Component
export default class AboutUs extends Vue {
  // COMPUTED
  get aboutUsColumns(): Array<string> {
    return Object.keys((this.$vuetify.lang.locales.en.aboutUs as VuetifyLocale & {columns: Record<string, AboutUsColumn>}).columns)
  }

  get keysOfStoryMarks(): Array<string> {
    return Object.keys((this.$vuetify.lang.locales.en.aboutUs as VuetifyLocale & {story: {storyMarks: Record<string, string>}}).story.storyMarks)
  }

  get keysOfConferences(): Array<string> {
    return Object.keys((this.$vuetify.lang.locales.en.aboutUs as VuetifyLocale & {meetUs: {conferences: IConference}}).meetUs.conferences)
  }

  get keysOfOurNextSteps(): Array<string> {
    return Object.keys((this.$vuetify.lang.locales.en.aboutUs as VuetifyLocale & {nextSteps: {steps: IConference}}).nextSteps.steps)
  }
}
