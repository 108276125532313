





















import { Vue, Component, Prop } from 'vue-property-decorator'
import { VuetifyThemeVariant } from 'vuetify/types/services/theme';

@Component
export default class DgmLink extends Vue {
  /* eslint-disable */
  @Prop() readonly caption: any;
  @Prop() readonly href: any;
  @Prop() readonly target: any;
  /* eslint-enable */

  focused = false;

  get theme(): VuetifyThemeVariant {
    return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark : this.$vuetify.theme.themes.light;
  }

  get linkStyle(): string {
    let style = 'text-decoration: none; ';
    if (this.focused) {
      style += 'color: ' + (this.theme.primary) + ';'
    } else {
      style += 'color: ' + (this.theme['label']) + ';'
    }
    return style;
  }

  doClick() {
    this.$emit('click');
  }
}
