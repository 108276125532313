var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{class:[
    {'flex-row-reverse': _vm.reverse && _vm.$vuetify.breakpoint.lgAndUp},
    {'flex-column': _vm.$vuetify.breakpoint.mdAndDown}
  ],style:(_vm.paddings),attrs:{"align":"center"}},[_c('v-col',{class:[
      {'pr-10': !_vm.reverse && _vm.$vuetify.breakpoint.lgAndUp},
      {'pl-10': _vm.reverse && _vm.$vuetify.breakpoint.lgAndUp},
      {'pb-4': _vm.$vuetify.breakpoint.mdAndDown}
    ],attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown ? 12 : 5}},[_c('v-img',{class:[
        {'mx-auto': _vm.$vuetify.breakpoint.mdAndDown}
      ],attrs:{"src":_vm.iconSrc,"max-width":"286","max-height":"286"}})],1),_c('v-col',{class:[
      {'pl-10': !_vm.reverse && _vm.$vuetify.breakpoint.lgAndUp},
      {'pr-10': _vm.reverse && _vm.$vuetify.breakpoint.lgAndUp},
      {'text-center': _vm.$vuetify.breakpoint.mdAndDown}
    ],attrs:{"cols":_vm.$vuetify.breakpoint.mdAndDown ? 12 : 7}},[_c('p',{staticClass:"mb-6 bright--text",class:[
        {'font--title-m': _vm.$vuetify.breakpoint.lgAndUp},
        {'font--heading-xl': _vm.$vuetify.breakpoint.mdAndDown}
      ]},[_vm._v(" "+_vm._s(_vm.heading)+" ")]),_c('p',{staticClass:"mb-6",class:[
        {'font--text-l': _vm.$vuetify.breakpoint.lgAndUp},
        {'font--text-m': _vm.$vuetify.breakpoint.mdAndDown}
      ]},[_vm._v(" "+_vm._s(_vm.text)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }