/* eslint-disable no-irregular-whitespace */
import { IVacancy } from '@/plugins/types';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

@Module({ name: 'vacancies' })
export default class VacanciesStore extends VuexModule {
  vacancies: Array<IVacancy> = []

  @Mutation
  setVacancies(data: Array<IVacancy>) {
    this.vacancies = data
  }

  @Action({ commit: 'setVacancies'})
  async getVacancies() {
    // const vakancies: Array<IVacancy> = [
    //   {
    //     role: 'Senior Java developer',
    //     departament: 'IT',
    //     description: 'We know that managing money and investing might be challenging. When traditional banking is constrained by rigid structures and regulation, cryptocurrencies give people control over their own money whether for cross border remittance or for investments. We see it  as our mission to build the most trusted, responsive and accessible platform that empowers people and businesses around the world to benefit from the cryptoeconomy and grow in new and powerful way. We know that managing money and investing might be challenging. When traditional banking is constrained by rigid structures and regulation, cryptocurrencies give people control over their own money whether for cross border remittance or for investments. We see it  as our mission to build the most trusted, responsive and accessible platform that empowers people and businesses around the world to benefit from the cryptoeconomy and grow in new and powerful way',
    //     responsibilities: [
    //       'Designing, development of core components/services which are flexible, extensible, multi-tier, scalable, high performance',
    //       'Providing technical leadership and mentoring to junior developers',
    //       'Developing use cases and design alternatives in accordance with business requirements',
    //       'Interact with Product Owner and Business analyst across geographies to leverage expertise and take up development to next level',
    //       'Participating in code reviews, working with business analyst and testers to effectively document and validate all implementations',
    //       'Ability to understand and discuss technical concepts, schedule tradeoffs and opportunistic new ideas with technical employees',
    //       'Working with development methodologies used by the company such as Agile'
    //     ],
    //     requirements: [
    //       'Strong passion for Full Stack development, deep knowledge of web frontend technology while understanding of Backend-side architecture',
    //       'Experience working with relational database, such as MS-SQL and/or Oracle, PostgreSQL',
    //       'Experience with NoSQL Databases such as MogoDB and/or Cassandra',
    //       'Proficiency with fundamental front-end languages such as HTML, CSS, and JavaScript',
    //       'Experience with JavaScript frameworks such as Angular, NodeJs and React',
    //       'Experienced working with XML and JSON, Spring, Hibernate and web services architecture, such as SOAP and/or REST',
    //       'Experience working with Open Source frameworks and Kubernetes clusters',
    //     ],
    //     offers: [
    //       'Annual bonus as 13th salary payment (in the amount of 1 salary)',
    //       'Paid lunch in the lunch restaurant in the 1st floor of office building (24/7 healthy snacks and beverages in out office canteen)',
    //       'Health insurance after trial period',
    //       'Flexible working hours to help you keep the work-life balance and find time for your hobbies',
    //       'Partnership approach & communication respecting individual needs and employee development plan ideas',
    //       'Possibility to gain professional experience and learn from global industry experts',
    //       'Stable project & diversified working environment',
    //       'Periodical events and contests for employees'
    //     ],
    //     salary: '1000 - 2000'
    //   },
    //   {
    //     role: 'Front end developer',
    //     departament: 'IT',
    //     description: 'We know that managing money and investing might be challenging. When traditional banking is constrained by rigid structures and regulation, cryptocurrencies give people control over their own money whether for cross border remittance or for investments. We see it  as our mission to build the most trusted, responsive and accessible platform that empowers people and businesses around the world to benefit from the cryptoeconomy and grow in new and powerful way. We know that managing money and investing might be challenging. When traditional banking is constrained by rigid structures and regulation, cryptocurrencies give people control over their own money whether for cross border remittance or for investments. We see it  as our mission to build the most trusted, responsive and accessible platform that empowers people and businesses around the world to benefit from the cryptoeconomy and grow in new and powerful way',
    //     responsibilities: [
    //       'Designing, development of core components/services which are flexible, extensible, multi-tier, scalable, high performance',
    //       'Providing technical leadership and mentoring to junior developers',
    //       'Developing use cases and design alternatives in accordance with business requirements',
    //       'Interact with Product Owner and Business analyst across geographies to leverage expertise and take up development to next level',
    //       'Participating in code reviews, working with business analyst and testers to effectively document and validate all implementations',
    //       'Ability to understand and discuss technical concepts, schedule tradeoffs and opportunistic new ideas with technical employees',
    //       'Working with development methodologies used by the company such as Agile'
    //     ],
    //     requirements: [
    //       'Strong passion for Full Stack development, deep knowledge of web frontend technology while understanding of Backend-side architecture',
    //       'Experience working with relational database, such as MS-SQL and/or Oracle, PostgreSQL',
    //       'Experience with NoSQL Databases such as MogoDB and/or Cassandra',
    //       'Proficiency with fundamental front-end languages such as HTML, CSS, and JavaScript',
    //       'Experience with JavaScript frameworks such as Angular, NodeJs and React',
    //       'Experienced working with XML and JSON, Spring, Hibernate and web services architecture, such as SOAP and/or REST',
    //       'Experience working with Open Source frameworks and Kubernetes clusters',
    //     ],
    //     advantageRequirements: [
    //       'Experience in Scala, Streaming, Functional programming, Data distribution, ElasticSearch',
    //       'Experience in Micro-services architecture',
    //       'Data experience with Kafka, Spark',
    //       'Knowledge in Insurance',
    //       'Test Driven Development'
    //     ],
    //     offers: [
    //       'Annual bonus as 13th salary payment (in the amount of 1 salary)',
    //       'Paid lunch in the lunch restaurant in the 1st floor of office building (24/7 healthy snacks and beverages in out office canteen)',
    //       'Health insurance after trial period',
    //       'Flexible working hours to help you keep the work-life balance and find time for your hobbies',
    //       'Partnership approach & communication respecting individual needs and employee development plan ideas',
    //       'Possibility to gain professional experience and learn from global industry experts',
    //       'Stable project & diversified working environment',
    //       'Periodical events and contests for employees'
    //     ],
    //     salary: ''
    //   }
    // ]
    const vakancies: Array<IVacancy> = []
    return vakancies;
  }

  get allVacancies(): Array<IVacancy> {
    return this.vacancies
  }
    
}