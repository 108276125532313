
















































import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import ColorsMixins from '@/mixins/ColorsMixins';

@Component
export default class VacancyListItem extends mixins(ColorsMixins) {
  @Prop() readonly role: string;
  @Prop() readonly departament: string;
  @Prop() readonly itemStyle: string;
  @Prop() readonly colorForHoverEffect: string

  //COMPUTED
  get hoverColor() {
    return this.hexToRGB(this.getColorCode(this.colorForHoverEffect), 0.5)
  }

  // METHODS
  action() {
    this.$emit('action')
  }
}
