









































import { Feature } from '@/plugins/types'
import { Vue, Component } from 'vue-property-decorator'
import { VuetifyLocale } from 'vuetify/types/services/lang'

@Component
export default class Card extends Vue {
  icons: Array<string> = [
    '/assets/globalCover.svg',
    '/assets/locker.svg',
    '/assets/speedometr.svg',
  ]

  // COMPUTED
  get cardFeatures(): Array<string> {
    return Object.keys((this.$vuetify.lang.locales.en.card as VuetifyLocale & {features: {columns: Record<string, Feature>}}).features)
  }
}
