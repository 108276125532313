

























































































































































import { INavItem } from '@/plugins/types';
import {Vue, Component, Prop} from 'vue-property-decorator'

@Component
export default class SideMenu extends Vue {
  @Prop() readonly showMenu: boolean;

  mainNavigation: Array<INavItem> = [
    {
      name: this.$vuetify.lang.t('$vuetify.navigation.home'),
      path: '/',
    },
    {
      name: this.$vuetify.lang.t('$vuetify.navigation.tradingPlatform'),
      path: '/platform',
    },
    {
      name: this.$vuetify.lang.t('$vuetify.navigation.fervidoCard'),
      path: '/card',
    },
    {
      name: this.$vuetify.lang.t('$vuetify.navigation.prices'),
      path: '/prices',
    }
  ]

  secondaryNavigation: Array<INavItem> = [
    {
      href: this.documentationLink,
      name: this.$vuetify.lang.t('$vuetify.system.documentation')
    },
    {
      name: this.$vuetify.lang.t('$vuetify.navigation.aboutUs'),
      path: '/about-us',
    },
    // {
    //   name: this.$vuetify.lang.t('$vuetify.navigation.career'),
    //   path: '/careers',
    // },
    {
      name: this.$vuetify.lang.t('$vuetify.navigation.contacts'),
      path: '/contacts',
    },
    // {
    //   name: this.$vuetify.lang.t('$vuetify.navigation.faq'),
    //   path: '/faq',
    // }
  ]

  // COMPUTED
  get loginLink() {
    return process.env.VUE_APP_LOGIN;
  }

  get mobileAppLinks(): Record<string, string> {
    return {
      android: process.env.VUE_APP_ANDROID_DOWNLOAD_LNK,
      ios: process.env.VUE_APP_IOS_DOWNLOAD_LNK
    }
  }

  get menuIsVisible() {
    return this.showMenu
  }
  
  set menuIsVisible(value){
    return
  }

  get documentationLink(): string {
    return process.env.VUE_APP_DOCUMENTATION_LINK;
  }

  // METHODS
  action() {
    this.$emit('closeMenu')
  }

  onOverlayClick() {
    if (!(this.$refs.drawer as Vue & {isActive: boolean}).isActive) {
      this.action()
    }
  }

  openHref(href: string) {
    window.open(href, '_blank')
    this.action()
  }
}
