var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:(_vm.cardBackgroundStyles)},[_c('v-container',{style:(("padding-bottom: " + (_vm.$vuetify.breakpoint.lgAndUp ? '35%' : _vm.$vuetify.breakpoint.xs ? '80%' : '60%')))},[_c('v-row',{staticClass:"pb-12",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"font--title-m text-center",class:[
            {'font--title-m': _vm.$vuetify.breakpoint.lgAndUp},
            {'font--title-s': _vm.$vuetify.breakpoint.mdAndDown}
          ]},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.home.products.heading"))+" ")])])],1),_c('v-row',_vm._l((_vm.productsColumns),function(prod,i){return _c('v-column',{key:("product-column-" + (i + 1)),ref:"products",refInFor:true,class:[
          {'mb-16': i + 1 !== _vm.productsColumns.length}
        ],attrs:{"colWidth":"6","heading":_vm.$vuetify.lang.t(("$vuetify.home.products.columns." + prod + ".heading")),"headingSize":_vm.$vuetify.breakpoint.lgAndUp ? 'l' : 'm',"headingHeight":_vm.productsHeadingHeight,"text":_vm.$vuetify.lang.t(("$vuetify.home.products.columns." + prod + ".text")),"textSize":_vm.$vuetify.breakpoint.lgAndUp ? 'm' : 's',"textHeight":_vm.productsTextHeight,"buttonText":_vm.$vuetify.lang.t(("$vuetify.home.products.columns." + prod + ".buttonValue"))},on:{"action":function($event){_vm.goTo(_vm.$vuetify.lang.t(("$vuetify.home.products.columns." + prod + ".link")))}}})}),1)],1),_c('v-container',{class:[
      {'pb-16': _vm.$vuetify.breakpoint.lgAndUp},
      {'pb-6': _vm.$vuetify.breakpoint.mdAndDown}
    ],style:(("background: " + (_vm.hexToRGB(this.bgPrimaryColor, 0.96)) + "; padding-top: " + (_vm.$vuetify.breakpoint.lgAndUp ? '107' : '80') + "px")),attrs:{"fluid":""}},[_c('v-row',{staticClass:"pb-12",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"font--title-m text-center",class:[
            {'font--title-m': _vm.$vuetify.breakpoint.lgAndUp},
            {'font--title-s': _vm.$vuetify.breakpoint.mdAndDown}
          ]},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.home.customers.heading"))+" ")])])],1),_c('v-row',{staticClass:"pb-15"},_vm._l((_vm.customerColumns),function(col,i){return _c('v-column',{key:("customers-column-" + (i + 1)),style:(_vm.$vuetify.breakpoint.mdAndDown && (i + 1 !== _vm.customerColumns.length) ? 'padding-bottom: 99px' : ''),attrs:{"colWidth":"4","heading":_vm.$vuetify.lang.t(("$vuetify.home.customers.columns." + col + ".heading")),"headingSize":"m","headingColor":"bright","text":_vm.$vuetify.lang.t(("$vuetify.home.customers.columns." + col + ".text")),"textSize":"s"}})}),1)],1),_c('v-container',{staticClass:"px-6 pb-16 mb-12",attrs:{"fluid":""}},[_c('v-card',{staticClass:"text-center rounded-xl bg-light dark--text",class:[
        {'pt-15': _vm.$vuetify.breakpoint.lgAndUp} ],attrs:{"flat":"","outlined":""}},[_c('v-card-title',{staticClass:"justify-center"},[_c('p',{staticClass:"mb-0 pt-15",class:[
            {'font--title-m pb-6': _vm.$vuetify.breakpoint.smAndUp},
            {'font--heading-m': _vm.$vuetify.breakpoint.xs}
          ]},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.home.whyBlock.heading"))+" ")])]),_c('v-card-text',{class:[
            {'pb-16': _vm.$vuetify.breakpoint.smAndUp},
            {'pb-8': _vm.$vuetify.breakpoint.xs}
          ]},[_c('p',{staticClass:"mb-0 dark--text mx-auto",class:[
            {'font--text-l': _vm.$vuetify.breakpoint.smAndUp},
            {'font--text-m': _vm.$vuetify.breakpoint.xs}
          ],staticStyle:{"max-width":"800px","width":"90%"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.home.whyBlock.text"))+" ")])]),_c('v-card-text',{staticClass:"pt-6 px-11 pb-16 mb-4"},[_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.whyBlockColumns),function(col,i){return _c('v-column',{key:("why-block-column-" + (i + 1)),ref:"whyCols",refInFor:true,class:[
              {'pb-5': _vm.$vuetify.breakpoint.xs && ( i + 1 !== _vm.whyBlockColumns.length)} ],attrs:{"colWidth":"4","heading":_vm.$vuetify.lang.t(("$vuetify.home.whyBlock.columns." + col + ".heading")),"headingSize":_vm.$vuetify.breakpoint.xs ? 's' : 'm',"headingHeight":_vm.whyColsHeadingHeight,"headingColor":"heading","text":_vm.$vuetify.lang.t(("$vuetify.home.whyBlock.columns." + col + ".text")),"textSize":"s","textColor":"dark"}})}),1)],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{style:(_vm.handedPhoneBackgroundStyles)}):_vm._e()],1)],1),_c('HowItWorks'),_c('Banner',{attrs:{"type":"getStarted"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }