


















































































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component'
import ColorsMixins from '@/mixins/ColorsMixins';
import { VuetifyLocale } from 'vuetify/types/services/lang';
import { ITarifGroup } from '@/plugins/types';

@Component
export default class PriceList extends mixins(ColorsMixins) {
  // COMPUTED
  get mainFiatCurrencySymbol(): string {
    return process.env.VUE_APP_MAIN_FIAT_CURRENCY_SYMBOL
  }

  get tarifGroupKeys(): Array<string> {
    return Object.keys((this.$vuetify.lang.locales.en.pricelist as VuetifyLocale & {tarifsGroup:  Record<string, ITarifGroup>}).tarifsGroup)
  }

  get borderColor(): string {
    return this.getColorCode('primary')
  }
}
