













import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ColorsMixins from '@/mixins/ColorsMixins';

@Component
export default class Prices extends mixins(ColorsMixins) {
  loader = true

  toggleLoader() {
    this.loader = !this.loader
  }  
}
