/* eslint-disable @typescript-eslint/no-empty-interface */
export { }

declare global {
  interface Number {
    format(dec: number, group: number, groupDelimiter: string, decimalChar: string, absolute: boolean): string;
    formatTrunc(dec: number, group: number, groupDelimiter: string, decimalChar: string, absolute: boolean): string;
    whole(group: number, groupDelimiter: string, absolute: boolean): string;
    frac(dec: number, decimalChar: string): string;
  }

  interface Date {
    format(withTime: boolean): string;
    formatTime(): string;
    formatTodayTimeOnly(): string;
    isToday(): boolean;
    isExpired(to: Date | null): boolean;
    native(withTime: boolean): string;
    addDay(n: number): Date;
    addMonth(n: number): Date;
    addYear(n: number): Date;
    duration(to: Date | null): string;
    mixDateAndTime(date: Date): Date;
    nowUTC(): Date;
    fromUTC(): Date;
  }
}

// eslint-disable-next-line
function correctNotation(x: any) {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += (new Array(e + 1)).join('0');
    }
  }
  return x;
}

/* eslint no-extend-native: ["error", { "exceptions": ["Number", "Date"] }] */

Number.prototype.format = function (dec: number, group: number, groupDelimiter: string, decimalChar: string, absolute: boolean): string {
  const value = absolute ? Math.abs(Number(this)) : this;

  const re = '\\d(?=(\\d{' + (group || 3) + '})+' + (dec > 0 ? '\\D' : '$') + ')'
  const num = correctNotation(value.toFixed(Math.max(0, ~~dec)));

  return (decimalChar ? num.replace('.', decimalChar) : num).replace(new RegExp(re, 'g'), '$&' + (groupDelimiter || ','));
}

Number.prototype.formatTrunc = function (dec: number, group: number, groupDelimiter: string, decimalChar: string, absolute: boolean): string {
  const value: string = this.format(dec, group, groupDelimiter, decimalChar, absolute);
  if (value.indexOf(decimalChar) > 0) {
    let res = '';
    let trunc = true;
    for (let i = value.length - 1; i >= 0; i--) {
      if (trunc && value.charAt(i) === '.') {
        trunc = false;
        continue;
      }

      if (trunc && value.charAt(i) !== '0') {
        trunc = false;
      }

      if (!trunc) {
        res = value.charAt(i) + res;
      }
    }

    return res;
  } else {
    return value;
  }
}

Number.prototype.whole = function (group: number, groupDelimiter: string, absolute: boolean): string {
  let num: number = Math.floor(Number(this));
  num = absolute ? Math.abs(num) : num;

  return num.format(0, group, groupDelimiter, '.', absolute);
}

Number.prototype.frac = function (dec: number, decimalChar: string): string {
  const num = Math.abs((Number(this) % 1) * Math.pow(10, dec))
  return decimalChar
    ? num < 10 ? decimalChar + '0' + num.toFixed(0) : decimalChar + num.toFixed(0)
    : num.toFixed(0);
}

Date.prototype.native = function (withTime: boolean): string {
  let s = this.getFullYear() +
    '-' +
    (this.getMonth() < 9 ? '0' + (this.getMonth() + 1) : (this.getMonth() + 1)) +
    '-' +
    (this.getDate() < 10 ? '0' + this.getDate() : this.getDate());

  if (withTime) {
    s = s + 'T' + (this.getHours() < 10 ? '0' + this.getHours() : this.getHours()) +
      ':' +
      (this.getMinutes() < 10 ? '0' + this.getMinutes() : this.getMinutes()) +
      ':' +
      (this.getSeconds() < 10 ? '0' + this.getSeconds() : this.getSeconds())
  }

  return s;
}

Date.prototype.format = function (withTime: boolean): string {
  let s = (this.getDate() < 10 ? '0' + this.getDate() : this.getDate()) +
    '.' +
    (this.getMonth() < 9 ? '0' + (this.getMonth() + 1) : (this.getMonth() + 1)) +
    '.' +
    this.getFullYear();

  if (withTime) {
    s = s + ' ' + this.formatTime();
  }

  return s;
}

Date.prototype.formatTime = function (): string {
  return (this.getHours() < 10 ? '0' + this.getHours() : this.getHours()) +
    ':' +
    (this.getMinutes() < 10 ? '0' + this.getMinutes() : this.getMinutes());
}

Date.prototype.isToday = function (): boolean {
  const today = new Date()
  return this.getFullYear() === today.getFullYear() && this.getMonth() === today.getMonth() && this.getDate() === today.getDate()
}

Date.prototype.isExpired = function (to: Date | null = null): boolean {
  const toDate = to || new Date();
  return this.getTime() - toDate.getTime() < 0;
}

Date.prototype.formatTodayTimeOnly = function (): string {
  if (this.isToday()) {
    return this.formatTime();
  } else {
    return this.format(true);
  }
}

Date.prototype.addDay = function (n: number): Date {
  this.setDate(this.getDate() + n);
  return this;
}

Date.prototype.addMonth = function (n: number): Date {
  this.setMonth(this.getMonth() + n);
  return this;
}

Date.prototype.addYear = function (n: number): Date {
  this.setFullYear(this.getFullYear() + n);
  return this;
}

Date.prototype.mixDateAndTime = function (date: Date): Date {
  const h = date.getHours();
  const m = date.getMinutes();
  const s = date.getSeconds();
  const result = new Date(this.getTime());
  result.setHours(h, m, s, 0);
  return result;
}

Date.prototype.nowUTC = function (): Date {
  const dt = new Date();
  return new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds()));
}

Date.prototype.fromUTC = function (): Date {
  return new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate(), this.getHours(), this.getMinutes(), this.getSeconds()));
}

/*
Date.prototype.setTimeString = function (time) {
  this.setHours(Number(time.substring(0, 2)));
  this.setMinutes(Number(time.substring(3, 5)));
  this.setSeconds(0);
  this.setMilliseconds(0);
  return this;
};

Date.prototype.diff = function (time) {
  var diff = time.getTime() - this.getTime();
  const hh = Math.floor(diff / 1000 / 60 / 60);
  diff -= hh * 1000 * 60 * 60;
  const mm = Math.floor(diff / 1000 / 60);
  return hh + ':' + (mm < 10 ? '0' + mm : mm);
};

String.prototype.replaceAt = function (index, replacement) {
  return this.substr(0, index) + replacement + this.substr(index + replacement.length);
};
*/

export enum EntityStates {
  Active = 'a',
  Blocked = 'b',
  Initialization = 'i',
}

export enum CurrencyType {
  Fiat = 'F',
  Crypto = 'C',
  Token = 'T',
}

export interface ICurrency {
  currencyCode: string;
  currencyName: string;
  currencyPrecision: number;
  currencyType: CurrencyType;
  currencyState: EntityStates;
}

export interface IExchange {
  buy: string;
  sell: string;
  rate: number | null;
  buyAmount: number | null;
  sellAmount: number | null;
  token: string;
}

export interface INavItem {
  path?: string;
  name: string;
  href?: string;
}

export interface IExtendedNavItem {
  heading: string;
  links: Array<INavItem>;
}

export interface Product {
  subHeading: string;
  heading: string;
  text: string;
  buttonValue: string;
  link: string;
}

export interface Contact {
  contactName: string;
  contactValue: string;
}

export interface Customer extends Omit<Product, 'subHeading'|'buttonValue'|'link'> {}
export interface WhyBlockCol extends Omit<Product, 'subHeading'|'buttonValue'|'link'> {}
export interface HowItWorkCol extends Omit<Product, 'buttonValue'|'link'> {}
export interface Feature extends Omit<Product, 'subHeading'|'buttonValue'|'link'> {}
export interface AboutUsColumn extends Omit<Product, 'subHeading'|'buttonValue'|'link'> {}

export interface IVacancy {
  role: string;
  departament: string;
  description: string;
  responsibilities: Array<string>;
  requirements: Array<string>;
  advantageRequirements?: Array<string>;
  offers: Array<string>;
  salary?: string;
}

export interface IVacancyListItem extends Omit<IVacancy, 'description'|'responsibilities'|'requirements'|'offers'> {}

export interface ICurrencyInfo {
  history?: Array<number>;
  gate: null | string;
  name: string | null;
  code: string | null;
  updated: null | string;
  marketCap: number | null;
  volume: number | null;
  circulating: number | string | null;
  allTimeHigh: number | string | null;
  price: number;
  changed1h: number | null;
  changed1d: number | null;
  changed1w: number | null;
  changed1m: number | null;
  changed1q: number | null;
  changed6m: number | null;
  changed1y: number | null;
  graph: Array<number>;
  graphDates: Array<string>;
  loading: boolean;
}

export interface IConference {
  inkUrl: string;
  imgUrl: string;
  subtext: string;
}

export interface ICurrencyGlossaryItem {
  name: string;
  code: string;
  website: string | null;
  whitepaper: string | null;
}

export interface ITarifGroup {
  groupName: string;
  positions: Record<string, IPositionTarifGroup>;
}

export interface IPositionTarifGroup {
  name: string;
  price?: string;
  fee?: string;
  min?: string;
  subCategory?: Record<string, {name: string; price: string}>;
}