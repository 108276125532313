var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-8"},[_c('div',{staticClass:"mx-auto",staticStyle:{"width":"90%","max-width":"815px"}},_vm._l((_vm.tarifGroupKeys),function(group,i){return _c('v-list',{key:("price-list-group-" + (i + 1)),class:[
        {'pb-14': i + 1 !== _vm.tarifGroupKeys.length}
      ],attrs:{"color":"transparent"}},[_c('v-list-item',{staticClass:"px-0",style:("border-bottom: 2px solid white")},[_c('v-list-item-content',[_c('p',{staticClass:"mb-0 font--bold",class:[
              {'font--text-l--tiny-line': _vm.$vuetify.breakpoint.smAndUp},
              {'font--text-s': _vm.$vuetify.breakpoint.xs}
            ]},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(("$vuetify.pricelist.tarifsGroup." + group + ".groupName")))+" ")])])],1),_vm._l((Object.keys(_vm.$vuetify.lang.locales.en.pricelist.tarifsGroup[group].positions)),function(position,j){return [(Object.keys(_vm.$vuetify.lang.locales.en.pricelist.tarifsGroup[group].positions[position]).includes('subCategory'))?[_c('v-list-item',{key:("price-list-group-" + (i + 1) + "-postition-name-" + (j + 1)),staticClass:"px-0",style:(("border-bottom: 1px solid " + (_vm.hexToRGB(_vm.borderColor, 0.2))))},[_c('v-list-item-content',[_c('span',{class:[
                  {'font--text-l--tiny-line': _vm.$vuetify.breakpoint.smAndUp},
                  {'font--text-s': _vm.$vuetify.breakpoint.xs}
                ]},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(("$vuetify.pricelist.tarifsGroup." + group + ".positions." + position + ".name")))+" ")])])],1),_c('v-list',{key:("price-list-group-" + (i + 1) + "-postition-sub-category-list-" + (j + 1)),attrs:{"color":"transparent"}},_vm._l((Object.keys(_vm.$vuetify.lang.locales.en.pricelist.tarifsGroup[group].positions[position].subCategory)),function(subCategory,a){return _c('v-list-item',{key:("price-list-group-" + (i + 1) + "-postition-" + (j + 1) + "-sub-category-" + (a + 1)),staticClass:"px-0 justify-end",style:(("border-bottom: 1px solid " + (_vm.hexToRGB(_vm.borderColor, 0.2))))},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('span',{class:[
                    {'font--text-l--tiny-line': _vm.$vuetify.breakpoint.smAndUp},
                    {'font--text-s': _vm.$vuetify.breakpoint.xs}
                  ]},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(("$vuetify.pricelist.tarifsGroup." + group + ".positions." + position + ".subCategory." + subCategory + ".name")))+" ")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('span',{class:[
                    {'font--text-l--tiny-line': _vm.$vuetify.breakpoint.smAndUp},
                    {'font--text-s': _vm.$vuetify.breakpoint.xs}
                  ]},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(("$vuetify.pricelist.tarifsGroup." + group + ".positions." + position + ".subCategory." + subCategory + ".value")))+" ")])])],1)}),1)]:[_c('v-list-item',{key:("price-list-group-" + (i + 1) + "-postition-" + (j + 1)),staticClass:"px-0",style:(("border-bottom: 1px solid " + (_vm.hexToRGB(_vm.borderColor, 0.2))))},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"6"}},[_c('span',{class:[
                  {'font--text-l--tiny-line': _vm.$vuetify.breakpoint.smAndUp},
                  {'font--text-s': _vm.$vuetify.breakpoint.xs}
                ]},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(("$vuetify.pricelist.tarifsGroup." + group + ".positions." + position + ".name")))+" ")])]),_c('v-col',{staticClass:"pl-0 text-right",attrs:{"cols":"6"}},[_c('span',{class:[
                  {'font--text-l--tiny-line': _vm.$vuetify.breakpoint.smAndUp},
                  {'font--text-s': _vm.$vuetify.breakpoint.xs}
                ]},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(("$vuetify.pricelist.tarifsGroup." + group + ".positions." + position + ".price")))+" ")])])],1)]]})],2)}),1),_c('Banner',{attrs:{"fluidContainer":"","type":"getStarted"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }