import { Breakpoint } from 'vuetify/types/services/breakpoint';

declare global {
  interface Window {
    /* eslint-disable */
    dataLayer?: object[];

    grecaptcha: any;
    capchaId: number | null;
    capchaToken: string | null;

    getCapcha(): Promise<string>;
    resetCapcha(): void;
    paddings(breakpoint: Breakpoint): string;
    startTask(key: string, delay: number, task: any): void;
    /* eslint-enable */
  }
}

/* eslint-disable */
const tasks: Record<string, number> = {};
window.startTask = function (key: string, delay: number, task: any): void {
  let id = tasks[key];
  if (id) {
    clearTimeout(id);
  }

  id = setTimeout(task, delay);

  tasks[key] = id;
}
/* eslint-enable */


window.paddings = function (breakpoint: Breakpoint): string {
  if (breakpoint.lgAndUp) {
    return 'padding-left: 100px; padding-right: 100px; padding-top: 30px; padding-bottom: 50px;'
  } else if (breakpoint.mdAndUp) {
    return 'padding-left: 50px; padding-right: 50px; padding-top: 15px; padding-bottom: 25px;'
  } else {
    return 'padding-left: 14px; padding-right: 16px; padding-top: 0px; padding-bottom: 50px;';
  }
}

function stopCapcha() {
  window.capchaId = null;
}

window.resetCapcha = function (): void {
  stopCapcha();
  window.capchaToken = null;
  window.grecaptcha.reset();
}

window.getCapcha = function (): Promise<string> {
  stopCapcha();
  window.grecaptcha.execute();
  let trys = 0;
  /* eslint-disable */
  return new Promise<string>((resolve: any, reject: any) => {
    const id = setInterval(() => {
      trys++;
      if (window.capchaToken) {
        stopCapcha();
        clearInterval(id);
        resolve(window.capchaToken);
      }

      if (trys > 600 || window.capchaId !== id) {
        stopCapcha();
        clearInterval(id);
        reject('Timeout')
      }
    }, 100);
    window.capchaId = id;
  });
  /* eslint-enable */
}

stopCapcha();

export type DataLayerEventName =
  | 'customUser'
  | 'customEvent'
  | 'customPage'
  | 'pageview'
  | 'customEcommerce';

interface UserData {
  userId: string | undefined;
}

interface EventData {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
}

interface EcommerceEventData {
  id: string;
  affiliation?: string;
  revenue: string;
  shipping?: string;
  currency?: string;
}

interface TransactionProduct {
  sku: string;
  name: string;
  category?: string;
  price: string;
  quantity: string;
}

interface PageData {
  path: string;
}

export interface DataLayerObject {
  event: DataLayerEventName;
  pageTitle?: string;
  userData?: UserData;
  eventData?: EventData;
  pageData?: PageData;
}

export interface EcommerceObject extends DataLayerObject {
  transactionId: string;
  transactionTotal: string;
  transactionAffiliation?: string;
  transactionShipping?: string;
  transactionTax?: string;
  transactionCurrency?: string;
  transactionProducts?: TransactionProduct[];
}

const pushToDataLayer = (obj: DataLayerObject) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
};

const setUserData = (userId: string) => {
  pushToDataLayer({
    event: 'customUser',
    userData: {
      userId
    }
  });
};

const createPageEvent = (path: string) => {
  const obj: DataLayerObject = {
    event: 'customPage',
    pageData: {
      path
    }
  };
  pushToDataLayer(obj);
};

const createPageViewEvent = (title: string) => {
  const obj: DataLayerObject = {
    event: 'pageview',
    pageTitle: title
  };
  pushToDataLayer(obj);
};

const createEcommerceEvent = (data: EcommerceEventData) => {
  const obj: EcommerceObject = {
    event: 'customEcommerce',
    transactionId: data.id,
    transactionAffiliation: data.affiliation,
    transactionTotal: data.revenue,
    transactionShipping: data.shipping,
    transactionCurrency: data.currency
  };
  pushToDataLayer(obj);
};

const createGenericEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number,
  nonInteraction?: boolean
) => {
  const obj: DataLayerObject = {
    event: 'customEvent',
    eventData: {
      category,
      action,
      label,
      value,
      nonInteraction
    }
  };
  pushToDataLayer(obj);
};

export default {
  createPageEvent,
  createPageViewEvent,
  createEcommerceEvent,
  createGenericEvent,
  setUserData
};
