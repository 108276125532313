import Vue from 'vue'
import DgmSwitch from './system/v-dgm-switch.vue'
import DgmLink from './system/v-dgm-link.vue'
import DataField from './system/v-data-field.vue'

import Column from './system/v-column.vue'

import Banner from './UI/Banner.vue'
import SideMenu from './main/SideMenu.vue'
import HowItWorks from './UI/HowItWorks.vue'
import Intro from './UI/Intro.vue'
import Feature from './UI/Feature.vue'
import Header from './main/Header.vue'
import Footer from './main/Footer.vue'
import VacancyListItem from './UI/VacancyListItem.vue'
import List from './UI/List.vue'
import CurrencyList from './UI/CurrencyList.vue'
import CurrenciesListItem from './UI/CurrenciesListItem.vue'
import Loader from './UI/Loader.vue'
import Chart from './UI/Chart.vue'

Vue.component('v-dgm-switch', DgmSwitch)
Vue.component('v-dgm-link', DgmLink)
Vue.component('v-data-field', DataField)
Vue.component('v-column', Column)

Vue.component('Header', Header)
Vue.component('Footer', Footer)
Vue.component('SideMenu', SideMenu)
Vue.component('Intro', Intro)
Vue.component('Banner', Banner)
Vue.component('Feature', Feature)
Vue.component('HowItWorks', HowItWorks)
Vue.component('VacancyListItem', VacancyListItem)
Vue.component('List', List)
Vue.component('CurrencyList', CurrencyList)
Vue.component('CurrenciesListItem', CurrenciesListItem)
Vue.component('Loader', Loader)
Vue.component('Chart', Chart)
