var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-15",class:[
    {'mt-12': _vm.$vuetify.breakpoint.smAndUp},
    {'mt-5 px-4': _vm.$vuetify.breakpoint.xs},
    {'d-flex flex-column justify-center': _vm.extended}
  ],style:(_vm.extended ? _vm.height : '')},[(_vm.title || _vm.title.length)?_c('p',{staticClass:"text-center white--text mx-auto mb-0",class:[
      {'font--title-xl': _vm.$vuetify.breakpoint.lgAndUp},
      {'font--title-s': _vm.$vuetify.breakpoint.mdAndDown}
    ],staticStyle:{"max-width":"813px"},domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),(_vm.showText)?_c('p',{staticClass:"pt-13 mx-auto text-center white--text font--title-xs",staticStyle:{"max-width":"813px"},domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }