import { ICurrencyGlossaryItem, ICurrencyInfo } from '@/plugins/types'
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import currGlosary from '@/plugins/currenciesGlossary'


interface UpdateGraphPayload {
  code: string;
  curr: ICurrencyInfo;
}

interface GetSingleCurrencyInfoPayload {
  code: string;
  period: string;
}

@Module({ name: 'currencies' })
export default class CurrenciesStore extends VuexModule {
  currencies: Record<string, ICurrencyInfo> = {}

  @Mutation
  setCurrencies(data: Record<string, ICurrencyInfo>) {
    this.currencies = data
  }
  @Mutation
  setSingleCurrencyInfo(payload: UpdateGraphPayload) {
    payload.curr.name = this.currencies[payload.code].name
    this.currencies[payload.code] = payload.curr
    this.currencies[payload.code].history = payload.curr.graph.slice()
    this.currencies[payload.code].code = payload.code
    this.currencies[payload.code].loading = false
  }

  @Action({ commit: 'setCurrencies', rawError: true })
  async getCurrencies(currLoadPropery = false) {
    const response = await fetch('/api/currencies/info', {
      method: 'GET'
    })
    const data = await response.json()
    const currencies: Record<string, ICurrencyInfo> = data.body
    Object.keys(currencies).forEach((code: string) => {
      Object.assign(currencies[code], { loading: currLoadPropery })
    })
    return currencies
  }

  @Action({ commit: 'setSingleCurrencyInfo' })
  async getSingleCurrencyInfo(payload: GetSingleCurrencyInfoPayload) {
    const response = await fetch(`/api/currencies/info/${payload.code}/${payload.period}`, {
      method: 'GET'
    })
    const data = await response.json()
    return { curr: data.body, code: payload.code }
  }

  get allCurrencies() {
    return this.currencies
  }

  get glossary(): Record<string, ICurrencyGlossaryItem> {
    return currGlosary
  }
}