















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Loader extends Vue {
  @Prop() readonly width: string | number;
  @Prop() readonly size: string | number;
  @Prop() readonly color: string;
}
