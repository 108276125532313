import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

import en from '@/locale/en';
import ru from '@/locale/ru';

Vue.use(Vuetify);

/* eslint-disable @typescript-eslint/camelcase */

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      light: {
        'primary': '#FFFFFF',
        'bright': '#FDD1BF',
        'dark': '#151A1B',
        'heading': '#3D7677',
        'green': '#8DC351',
        'bg-dark': '#151A1B',
        'bg-light': '#FFFFFF',
        'gradient': '#151A1B',
        'input-border': '#2F3839',
        'blur': '#444C4D',
        'disabled': '#BED0F0',
        'success': '#008900',
        'danger': '#EC5151',
      },
      dark: {
        'primary': '#FFFFFF',
        'bright': '#FDD1BF',
        'dark': '#151A1B',
        'heading': '#3D7677',
        'green': '#8DC351',
        'bg-dark': '#151A1B',
        'bg-light': '#FFFFFF',
        'gradient': '#151A1B',
        'input-border': '#2F3839',
        'blur': '#444C4D',
        'disabled': '#BED0F0',
        'success': '#008900',
        'danger': '#EC5151',
      }
    }
  },
  icons: {
    iconfont: 'fa',
  },
  lang: {
    locales: { en, ru },
    current: 'en',
  },
});

/* eslint-enable @typescript-eslint/camelcase */
