















































import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class List extends Vue {
  @Prop() readonly icon: string;
  @Prop() readonly iconSize: string | number;
  @Prop() readonly iconColor: string;
  @Prop() readonly list: Array<string>;
  @Prop({default: ''}) readonly heading: string;

  get headingExist(): boolean {
    return this.heading !== '' && Boolean(this.heading.length) || false
  }
}
