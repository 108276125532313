var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-col',{staticClass:"pa-0",style:(((_vm.borderStyles.top) + "; " + (_vm.borderStyles.bottom) + "; " + (_vm.doShadow && !_vm.mouseOver ? ("opacity: " + (20 - _vm.shadowValue) + "%;") : '') + " transition: all 0.45s linear; cursor: pointer")),attrs:{"cols":"12"},on:{"mouseenter":function($event){_vm.mouseOver = !_vm.mouseOver},"mouseleave":function($event){_vm.mouseOver = !_vm.mouseOver},"click":_vm.action}},[_c('v-toolbar',{staticClass:"js-class",staticStyle:{"transition":"all 0.3s linear"},attrs:{"flat":"","height":_vm.height,"max-width":815,"color":hover ? _vm.hoverColor : 'transparent',"tag":"div"}},[(_vm.currency.loading)?_c('Loader',{attrs:{"size":"60","width":"2","color":"bright"}}):_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',[_c('v-img',{attrs:{"width":_vm.$vuetify.breakpoint.mdAndUp ? 64 : 48,"height":_vm.$vuetify.breakpoint.mdAndUp ? 64 : 48,"src":("/assets/currencies/" + (_vm.currency.code) + ".svg")}})],1),_c('v-col',[_c('p',{staticClass:"mb-0 pb-2",class:[
              {'font--text-l--tiny-line': _vm.$vuetify.breakpoint.mdAndUp},
              {'font--text-s': _vm.$vuetify.breakpoint.smAndDown}
            ]},[_vm._v(" "+_vm._s(_vm.currency.name)+" ")]),_c('p',{staticClass:"mb-0",class:[
              {'font--text-l--tiny-line': _vm.$vuetify.breakpoint.mdAndUp},
              {'font--text-s': _vm.$vuetify.breakpoint.smAndDown}
            ],staticStyle:{"opacity":"60%"}},[_vm._v(" "+_vm._s(_vm.currency.code)+" ")])]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-spacer'):_vm._e(),(_vm.currency.history && _vm.currency.history.length)?_c('v-col',[_c('v-sparkline',{attrs:{"value":_vm.currency.history.slice(1, _vm.currency.history.length - 1),"gradient":[['#8EB4FF'], ['#68EF9E']],"line-width":"2","auto-draw":""}})],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-spacer'):_vm._e(),_c('v-col',[_c('p',{staticClass:"mb-0 pb-1 text-right font--text-s"},[_vm._v(" "+_vm._s(_vm.mainFiatCurrencySymbol)+" "+_vm._s(_vm.currency.price.toFixed(2))+" ")]),_c('p',{staticClass:"font--text-s text-right",class:[
              {'green--text': Number(_vm.formatedPriceChange) > 0},
              {'red--text': Number(_vm.formatedPriceChange) < 0},
              {'primary--text': Number(_vm.formatedPriceChange) === 0}
            ]},[_vm._v(" "+_vm._s(_vm.formatedPriceChange)+" % "),_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.system.diaposons.changed1d'))+" ")])])])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }