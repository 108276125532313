import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import globals from '@/plugins/globals'

import Home from '@/views/Home.vue'

import Platform from '@/views/Platform.vue'
import Card from '@/views/Card.vue'

import Contacts from '@/views/Contacts.vue'
import AboutUs from '@/views/AboutUs.vue'
import PriceList from '@/views/PriceList.vue'

import Prices from '@/views/Prices.vue'
import Currency from '@/views/CurrencyReview.vue'

// import Careers from '@/views/Careers.vue'
import VacancyReview from '@/views/VacancyReview.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/platform',
    name: 'Platform',
    component: Platform
  },
  {
    path: '/card',
    name: 'Card',
    component: Card
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/price-list',
    name: 'PriceList',
    component: PriceList
  },
  {
    path: '/prices',
    name: 'Prices',
    component: Prices
  },
  {
    path: '/prices/:code',
    name: 'Currency',
    component: Currency
  },
  // {
  //   path: '/careers',
  //   name: 'Careers',
  //   component: Careers
  // },
  {
    path: '/careers/:role,:index',
    name: 'VacancyReview',
    component: VacancyReview
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "about" */ '../views/Privacy.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "about" */ '../views/Terms.vue')
  },
  {
    path: '/aml',
    name: 'AML',
    component: () => import(/* webpackChunkName: "about" */ '../views/AML.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    }
    return { x: 0, y: 0 }
  }
})

router.afterEach((to) => {
  if (to.name) {
    globals.createPageViewEvent(to.name)
  }
})

export default router
