





































































import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Header extends Vue {
  language = ''
  langs: Array<string> = []

  // COMPUTED
  get currLang(): string {
    return this.language
  }

  set currLang(value) {
    this.language = value;
    this.$vuetify.lang.current = value.toLowerCase();
  }

  // METHODS
  switchMenu() {
    this.$emit('switchMenu')
  }

  // LIFE CYCLE HOOKS
  beforeMount() {
    this.language = this.$vuetify.lang.current.toUpperCase()
    this.langs = Object.keys(this.$vuetify.lang.locales).map((l: string) => l.toUpperCase());
  }


}
