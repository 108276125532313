import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Translations extends Vue {
  i18n(key: string, ...args: Array<string>): string {
    let text: string = this.$vuetify.lang.t(key);
    if (args.length) {
      for (let i = 0; i < args.length; i++) {
        const replaceString: string = '%' + (i + 1).toString();
        text = text.replace(replaceString, args[i]);
      }
    }
    return text;
  }
}